.App {
  text-align: center;
  background-color: #caeaf3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Full-section {
  min-height: 100vh;
}

.Half-section {
  min-height: 50vh;
}

.Flex-top-central {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Flex-central-central {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avatar-img {
  border-radius: 20%;
  border: 3px solid rgb(204, 204, 204);
}

.Pretty-container {
  border-radius: 2px;
  background-color: white;
  margin: 10px;
  padding: 15px;
  position: relative;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.Pretty-container-bordered {
  background-color: white;
  margin: 10px;
  padding: 15px;
  position: relative;
  border-radius: 3px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
}

.App-link {
  color: #61dafb;
}

 /* The animation code */
 @keyframes fade_in_out {
  0%   {color: rgba(0, 0, 0, 0)}
  50%  {color: #46a2bb}
  100% {color: rgba(0, 0, 0, 0)}
}
/* The element to apply the animation to */
.update-spinner {
  color: rgba(0, 0, 0, 0);
  min-width: 20px;
  max-width: 20px;
  width: 20px;
  animation-name: fade_in_out;
  animation-duration: 7s;
} 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Disables plotly double click notification */
div.plotly-notifier {
  visibility: hidden;
}